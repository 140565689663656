<template>
  <v-card flat class="page">
    <v-card-title>
      Cotações {{ total > 0 ? `(${total})` : ''}}
      <v-spacer></v-spacer>
      <v-text-field
        :value="q"
        @input="search($event)"
        append-icon="mdi-magnify"
        solo
        label="Digite para buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      class="table"
      :headers="headers"
      :items="items"
      :items-per-page="limit"
      :loading="$wait.waiting('loading')"
      @click:row="goToDetail($event)"
      fixed-header
      height="inherit"
      hide-default-footer
    >
      <template slot="no-data">
        <div class="no-data-found">
          Nenhum resultado encontrado
          <v-btn @click="fetch(page)" color="blue" dark class="mt-2">
            Carregar novamente
          </v-btn>
        </div>
      </template>
      <template v-slot:item.vehicle="{ item }">
        {{ vehicleModel(item) }}
      </template>

      <template v-slot:item.vehiclePrice="{ item }">
        {{ vehiclePrice(item) }}
      </template>

      <template v-slot:item.proposalPrice="{ item }">
        {{ proposalPrice(item) }}
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | FormatDate('datetime') }}
        {{ item.author ? `(${item.author.name})` : ''}}
      </template>
    </v-data-table>
    <div class="pagination text-center py-2">
      <v-pagination
        :value="page"
        @input="fetch($event)"
        :length="pageLength"
      ></v-pagination>
    </div>
  </v-card>
</template>
<script>
import { mapActions } from 'vuex'
import { mapWaitingActions } from 'vue-wait'
import _ from 'lodash'
import formatCents from '@/helpers/formatCents'

export default {
  name: 'Quotation',

  data() {
    return {
      q: '',
      limit: 50,
      page: 1,
      headers: [
        {
          text: 'Cliente',
          align: 'start',
          filterable: false,
          value: 'customer.name',
        },
        { text: 'Veículo', value: 'vehicle' },
        { text: 'Valor FIPE veículo', value: 'vehiclePrice' },
        { text: 'Valor da proposta', value: 'proposalPrice' },
        { text: 'Criado em', value: 'createdAt' },
      ],
      result: null
    }
  },

  async created() {
    this.fetch(this.page)
  },

  computed: {
    pageLength() {
      return Math.ceil(this.total/this.limit) || 1
    },

    items() {
      return _.get(this.result, 'hits', [])
    },

    total() {
      return _.get(this.result, 'total', 0)
    }
  },

  methods: {
    ...mapActions("quotation", ["list"]),
    ...mapWaitingActions("quotation", {
      list: 'loading'
    }),

    async fetch(page) {
      this.page = page
      this.result = await this.list({
        page: page - 1,
        limit: this.limit
      })
    },

    goToDetail(row) {
      this.$router.push({
        name: 'quotation-editor',
        params: {
          id: row.id,
          data: row
        }
      })
    },

    vehicleModel(quotation) {
      return `${_.get(quotation, "vehicle.brand", "")} ${_.get(
        quotation,
        "vehicle.model",
        ""
      )} (${_.get(quotation, "vehicle.year", "")})`;
    },

    vehiclePrice(quotation) {
      return formatCents(_.get(quotation, 'vehicle.price', 0))
    },

    proposalPrice(quotation) {
      const finalPurchasePrice = formatCents(_.get(quotation, 'proposal.finalPurchasePrice', 0))
      const recurrencePrice = formatCents(_.get(quotation, 'proposal.recurrencePrice', 0))

      return `${finalPurchasePrice} + ${recurrencePrice}/mês`
    },

    search: _.debounce(async function(q){
      this.result = await this.list({ page: 0, limit: this.limit, q: q })
    }, 500, { leading: false, trailing: true }),
  }

}
</script>
<style type="text/css" scoped>
.page {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.table {
  flex: 1 1 auto;
  height: calc(100vh - 205px);
}
.table >>> tbody tr :hover {
  cursor: pointer;
}
.no-data-found {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100px;
}
.pagination {
  flex: none;
  background: #fff;
  border-top: 1px solid #e0e0e0;
}
</style>